import { CustomAlpine } from "../sheets/global";
import { ScheduleInput } from "./ScheduleInput";

let schedule_input: ScheduleInput;

const initializeState = (Alpine: CustomAlpine) => {
    Alpine.store('scheduleinput', {
        isClearAllEntities: false,
        isClearAll: false,
        showRows: true,
        showSetup: false,
        showStandard: true,
        rowGroupLastUpdated: null,
        currentDragType: null,
        currentDragStartCell: null,
        currentDragEndCell: null,
        lastClickedRow: null,
        lastClickedCol: null,
        selectedSchedules: {},
        selectedEntities: {},
        field_filter_map: JSON.parse(document.getElementById("x-scheduleinput").dataset.fieldFilterMap, (key, value) => {
            if (value === 'true') return true;
            if (value === 'false') return false;
            return value;
        }),
        entityFilterList: JSON.parse(document.getElementById("x-scheduleinput").dataset.entityFilterList),
        inSetupMode: document.getElementById("x-scheduleinput").dataset.inSetupMode === "True" ? true : false,
        open: false,  // Dropdown open state
        entityOpen: false,  // Dropdown open state
        hoverMap: {},
        init() {
            schedule_input = new ScheduleInput(this);

            // console.log('this.field_filter_map', this.field_filter_map)
            // for (const field of Object.keys(this.field_filter_map)) {
            //     console.log('field', field);
            // }
            // console.log('test...');
            // console.log('field_filter_map', this.field_filter_map);

              Object.keys(this.field_filter_map).forEach(key => {
                this.hoverMap[key] = [];

                this.field_filter_map[key].forEach(item => {
                    Object.keys(item).forEach(subKey => {
                        // Initialize the subKey in hoverMap if it doesn't exist
                        if (!this.hoverMap[subKey]) {
                            this.hoverMap[subKey] = [];
                        }
                        // Check if the main key already exists in the array for the subKey
                        if (!this.hoverMap[subKey].includes(key)) {
                            this.hoverMap[subKey].push(key);
                        }
                    });
                });
            });


            console.log('this.hoverMap', this.hoverMap);

            // Initialize selected with all schedules unselected
            const selectScheduleList = JSON.parse(document.getElementById("x-scheduleinput").dataset.selectScheduleList);
            // console.log('selectScheduleList', selectScheduleList);
            for (const schedule_id of selectScheduleList) {
                // console.log('schedule_id', schedule_id);
                this.selectedSchedules[schedule_id] = true;
            }
            // console.log('this.selected', this.selected);

            const checkboxes = document.querySelectorAll("[x-scheduleselect]");
            checkboxes.forEach(checkbox => {
                checkbox.checked = true;
                const schedule_id = checkbox.value;
                this.selectedSchedules[schedule_id] = true;
            });

            // document.querySelectorAll('select').forEach(selectElement => {
            //     this.filterRowsByConditions({
            //         target: selectElement
            //     });
            // });

            // these are the selects within the form
            document.querySelectorAll('select').forEach(selectElement => {
                selectElement.addEventListener('change', this.filterRowsByConditions.bind(this));
            });
            document.querySelectorAll('input[type="checkbox"]').forEach(checkboxElement => {
                checkboxElement.addEventListener('change', this.filterRowsByConditions.bind(this));
            });
            document.querySelectorAll('input[type="number"]').forEach(checkboxElement => {
                checkboxElement.addEventListener('change', this.filterRowsByConditions.bind(this));
            });

            // Call filterRowsByConditions on init without an event
            this.filterRowsByConditions();

            // Initialize selected with all schedules and entities selected
            const entityFilterList = JSON.parse(document.getElementById("x-scheduleinput").dataset.entityFilterList);
            for (const entity_id of entityFilterList) {
                this.selectedEntities[entity_id] = true;
            }

            const entityCheckboxes = document.querySelectorAll("[x-entityselect]");
            // console.log('entityCheckboxes', entityCheckboxes);
            entityCheckboxes.forEach(checkbox => {
                checkbox.checked = true;
                const entity_id = checkbox.value;
                this.selectedEntities[entity_id] = true;
            });

        },

        onSetupRowHover(row) {
            let relatedRows = this.getRelatedStandardRows(row);
            // console.log('relatedRows', relatedRows, relatedRows.length);
            if (relatedRows.length > 0) {
                // Highlight the setup row
                // console.log('row-----------', row)
                let setupCells = row.querySelectorAll('td');
                // console.log('setupCells', setupCells)
                setupCells.forEach(cell => cell.style.backgroundColor = '#c17025');

                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('td');
                    cells.forEach(cell => cell.style.backgroundColor = '#c17025');
                });

                // for F2 select
                row.querySelectorAll('select').forEach(cell => cell.style.backgroundColor = '#c17025');
                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('select');
                    cells.forEach(cell => cell.style.backgroundColor = '#c17025');
                });

                // for F2 input
                row.querySelectorAll('input').forEach(cell => cell.style.backgroundColor = '#c17025');
                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('input');
                    cells.forEach(cell => cell.style.backgroundColor = '#c17025');
                });
            }
        },

        onSetupRowMouseLeave(row) {
            let relatedRows = this.getRelatedStandardRows(row);
            if (relatedRows.length > 0) {
                // Remove the highlight from the setup row
                let setupCells = row.querySelectorAll('td');
                setupCells.forEach(cell => cell.style.backgroundColor = '');

                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('td');
                    cells.forEach(cell => cell.style.backgroundColor = '');
                });

                // for F2 select
                row.querySelectorAll('select').forEach(cell => cell.style.backgroundColor = '');
                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('select');
                    cells.forEach(cell => cell.style.backgroundColor = '');
                });

                // for F2 input
                row.querySelectorAll('input').forEach(cell => cell.style.backgroundColor = '');
                relatedRows.forEach(row => {
                    let cells = row.querySelectorAll('input');
                    cells.forEach(cell => cell.style.backgroundColor = '');
                });
            }
        },
        getRelatedStandardRows(row) {
            let relatedRows = [];
            const hoverKey = row.getAttribute('x-rowkey');
            // console.log('   hoverKey', hoverKey);

            // Check if hoverKey exists in hoverMap
            if (this.hoverMap[hoverKey]) {
                // Get the keys related to hoverKey from this.hoverMap
                const relatedKeys = this.hoverMap[hoverKey];
                // console.log('       relatedKeys', relatedKeys);

                // Loop through relatedKeys and get the matching rows
                relatedKeys.forEach(key => {
                    const matchingRows = document.querySelectorAll(`[x-rowkey="${key}"]`);
                    // console.log('key=========', key);
                    relatedRows.push(...matchingRows);
                });
            }

            return relatedRows;
        },
        // Example implementation
        // function getRelatedStandardRows(setupRow) {
        //     // Get the related key from the setup row
        //     const relatedKey = setupRow.getAttribute('data-related-key');

        //     // Get the rows in the standard table with the same related key
        //     const relatedRows = document.querySelectorAll(`.standard-table-row[data-related-key="${relatedKey}"]`);

        //     return Array.from(relatedRows);
        // }


        getScheduleInputRows() {
            const schedule_input = document.getElementById('x-scheduleinput');
        },
        toggleSetupVisibility() {
            this.showSetup = !this.showSetup;
            return this.showSetup;  // return the new state
        },
        toggleStandardVisibility() {
            this.showStandard = !this.showStandard;
            return this.showStandard;  // return the new state
        },
        toggleRowsVisibility() {
            this.showRows = !this.showRows;
            return this.showRows;  // return the new state
        },
        filterEntityColumnsByCheckbox() {
            Object.keys(this.selectedEntities).forEach((entityName) => {
                const isSelected = this.selectedEntities[entityName];
                const cells = document.querySelectorAll(`[x-entityname="${entityName}"]`);
                cells.forEach((cell) => {
                    // Show the cell if its entity is selected, and hide otherwise
                    cell.style.display = isSelected ? "" : "none";
                });
            });
        },
        filterRowsByConditions(event) {
            let rows = document.querySelectorAll("[x-scheduleinputrow]");

            let inputText = document.getElementById("searchlabels").value.toLowerCase();
            // console.log('aaaaaaaaaaaaaaaaaaaaa', inputText)
            rows.forEach((row) => {

                // 1: Check if conditions pass
                let isVisible = true;
                const rowKey = row.getAttribute("x-rowkey");
                if (rowKey in this.field_filter_map) {
                    let condition_list = this.field_filter_map[rowKey];
                    if (Object.keys(condition_list[0]).length){
                        // console.log('condition_list', condition_list)
                        isVisible = condition_list.some((c) => this.evaluateConditionSet(c,event));
                        console.log('isVisible', isVisible)
                    }
                }

                // 2: Check if schedule is selected
                const rowValue = row.getAttribute("x-scheduledefinedname");
                isVisible = isVisible && this.selectedSchedules[rowValue];

                // 3: Check if passes text search
                const rowText = rowKey ? rowKey.toLowerCase() : "";
                isVisible = isVisible &&  rowText.includes(inputText);

                // Apply display style based on result
                row.style.display = isVisible ? "" : "none";
            });
        },

        toggleDropdown() {
            // console.log('toggleDropdown');
            this.open = !this.open;
        },
        closeDropdown() {
            this.open = false;
        },

        toggleEntityDropdown() {
            // console.log('toggleDropdown');
            this.entityOpen = !this.entityOpen;
        },
        closeEntityDropdown() {
            this.entityOpen = false;
        },

        clearAll() {
            // clear checkboxes
            const checkboxes = document.querySelectorAll("[x-scheduleselect]");
            checkboxes.forEach(checkbox => {
                if (checkbox.offsetParent !== null) { // if the checkbox isn't filtered out
                    checkbox.checked = false;
                    // Also update the `selected` object
                    const schedule_id = checkbox.value;
                    this.selectedSchedules[schedule_id] = false;
                }
            });
            this.filterRowsByConditions();
        },
        clearAllEntities() {
            // console.log('clearAllEntities')
            // Get all entity checkboxes
            const entityCheckboxes = document.querySelectorAll("[x-entityselect]");

            entityCheckboxes.forEach(checkbox => {
                if (checkbox.offsetParent !== null) { // if the checkbox isn't filtered out
                    checkbox.checked = false;
                    // Also update the `selected` object
                    const entity_id = checkbox.value;
                    this.selectedEntities[entity_id] = false;

                    // Select and hide cells for this entity
                    let cells = document.querySelectorAll(`[x-entityname="${entity_id}"]`);
                    // console.log('cells', cells);
                    cells.forEach((cell) => {
                        cell.style.display = "none";
                    });
                }
            });
            this.isClearAll = true;
        },
        selectAll() {
            this.isClearAll = false;
            const checkboxes = document.querySelectorAll("[x-scheduleselect]");
            checkboxes.forEach(checkbox => {
                if (checkbox.offsetParent !== null) { // if the checkbox isn't filtered out
                    checkbox.checked = true;
                    // Also update the `selected` object
                    const schedule_id = checkbox.value;
                    this.selectedSchedules[schedule_id] = true;
                }
            });

            // Filter rows and reapply visibility conditions
            this.filterRowsByConditions();
        },
        selectAllEntities() {
            this.isClearAllEntities = false;
            // Get all entity checkboxes
            const entityCheckboxes = document.querySelectorAll("[x-entityselect]");
            entityCheckboxes.forEach(checkbox => {
                if (checkbox.offsetParent !== null) { // if the checkbox isn't filtered out
                    checkbox.checked = true;
                    // Also update the `selected` object
                    const entity_id = checkbox.value;
                    this.selectedEntities[entity_id] = true;
                }
            });

            // Filter columns by current selection
            setTimeout(() => {
                this.filterEntityColumnsByCheckbox();
            }, 0);
        },
        isSelected(schedule_id) {
            // console.log('isSelected', schedule_id, this.selected[schedule_id]);
            return this.selectedSchedules[schedule_id];
        },
        isSelectedEntities(entity_id) {
            return this.selectedEntities[entity_id] ?? false;
        },
        checkboxChanged(event) {
            // Extract checkbox value and checked state
            const checkbox = event.target;
            const schedule_id = checkbox.value;
            const isChecked = checkbox.checked;
            // Update the `selected` object
            this.selectedSchedules[schedule_id] = isChecked;

            // Filter rows
            setTimeout(() => {
                if (!isChecked) {
                    // If checkbox is unchecked, hide all associated rows
                    let rows = document.querySelectorAll(`[x-scheduledefinedname="${schedule_id}"]`);
                    // console.log('rows', rows);
                    rows.forEach(row => {
                        row.style.display = "none";
                    });
                } else {
                    // If checkbox is checked, filter rows by current search input and reapply visibility conditions
                    if (!this.isClearAll) {
                        this.filterRowsByConditions();
                    }
                }
            }, 0);
            this.filterRowsByConditions();

            // If any checkbox is checked, clearAll state must be false
            if (isChecked) {
                this.isClearAll = false;
            }
        },


        checkboxChangedEntities(event) {
            // Extract checkbox value and checked state
            const checkbox = event.target;
            const entity_id = checkbox.value;
            const isChecked = checkbox.checked;
            // Update the `selectedEntities` object
            this.selectedEntities[entity_id] = isChecked;

            // Filter entity columns
            setTimeout(() => {
                this.filterEntityColumnsByCheckbox();
            }, 0);

            // If any checkbox is checked, clearAll state must be false
            if (isChecked) {
                this.isClearAllEntities = false;
            }
        },

        // Helper function to evaluate a condition set
        evaluateConditionSet(condition_set, event) {
            // Required because if current select is changed, cannot get the value from querySelector
            const currentSelectElement = event ? event.target : undefined;
            let currentSelectElementParent = currentSelectElement ? currentSelectElement.closest('tr') : undefined;
            const currentSelectElementRowKey = currentSelectElementParent ? currentSelectElementParent.getAttribute("x-rowkey") : undefined;

            for (let [condition_key, condition_value] of Object.entries(condition_set)) {
                // console.log("eeeeeeeee', condition_key, condition_value", condition_key, condition_value)
                let actual_row = document.querySelector(`[x-rowkey="${condition_key}"]`);
                // console.log('ggggggggg', actual_row)

                // Query for the input element within the row. This includes selects, checkboxes, and radios.
                let inputElement = actual_row ? actual_row.querySelector('select, input[type="checkbox"], input[type="number"]') : null;
                if (!inputElement) {
                    console.error("Input element not found for:", condition_key);
                    return false; // Exit if input element is not found
                }

                // The handling for different input types is done here, but ultimately,
                // we want to use this section to set `actual_select` correctly.
                let inputValue
                let targetValue

                // For checkboxes and radios, adjust the value to be used for comparison.
                if (inputElement.type === "checkbox") {
                    // Convert boolean to string if needed. Adjust based on how you want to compare.
                    if (inputElement.checked) {
                        inputValue = "checked";
                    } else {
                        inputValue = "unchecked";
                    }
                }
                if (inputElement.type === "number") {
                    inputValue = inputElement.value;
                }
                if (inputElement.type === "select-one") {
                    inputValue = inputElement.value;
                }

                if (event && event.target) {
                    if (event.target.type === "checkbox") {
                        // Convert boolean to string if needed. Adjust based on how you want to compare.
                        if (event.target.checked) {
                            targetValue = "checked";
                        } else {
                            targetValue = "unchecked";
                        }
                    }
                    if (event.target.type === "number") {
                        targetValue = event.target.value;
                    }
                    if (event.target.type === "select-one") {
                        targetValue = event.target.value;
                    }
                }
                // Note: No need for an else branch for 'select' or 'text',
                // as `actual_select.value` will naturally work for these cases.
                console.log('inputValue', inputValue, inputElement.type, inputElement)

                let actual_value;
                if (currentSelectElementRowKey === condition_key) {
                    // If the current select element is the one being evaluated, use its current value.
                    actual_value = targetValue;
                } else {
                    // Otherwise, use the value from the input element relevant to the current condition.
                    actual_value = inputValue;
                }

                if (actual_value !== condition_value) {
                    return false;  // Return early if a condition is not met
                }
            }

            return true;  // All conditions were met
        },


    });


    let setup_table = document.getElementById("setup-table-container");
    let standard_table = document.getElementById("table-container");
    // console.log("before.........")
    let combined_table_container = document.getElementById("combined-table-container");
    // console.log("after.........")
    let toggle_input_setup_btn = document.getElementById("toggle-setup-button");
    let toggle_input_standard_btn = document.getElementById("toggle-standard-button");
    // let toggle_setup_label = document.querySelector("label[for='toggle-setup-button']");

    // Search input filter
    document.getElementById("searchlabels").addEventListener('input', function() {
        if (!Alpine.store('scheduleinput').isClearAll) {
            Alpine.store('scheduleinput').filterRowsByConditions();
        }

        document.getElementById("searchlabels-clear").style.display = document.getElementById("searchlabels").value == "" ? 'none' : null;
    });

    document.getElementById("searchlabels-clear").addEventListener('click', function(evt) {
        document.getElementById("searchlabels").value = ''
        document.getElementById("searchlabels-clear").style.display = 'none';
        Alpine.store('scheduleinput').filterRowsByConditions();
    });

    // console.log("rrrrrrrrrrr", document.getElementById("x-scheduleinput").dataset.inSetupMode)
    if (Alpine.store('scheduleinput').inSetupMode) {
        toggle_input_setup_btn.addEventListener('click', function() {
            let showSetup = Alpine.store('scheduleinput').toggleSetupVisibility();
            let showStandard = Alpine.store('scheduleinput').showStandard;

            setup_table.style.display = showSetup ? "grid" : "none";
            const label = toggle_input_setup_btn.querySelector('.label')
            if (label) {
                label.textContent = showSetup ? "Hide Setup" : "Show Setup";
            }
            combined_table_container.style.gridTemplateColumns = showSetup && showStandard ? "1fr 1fr" : "1fr";
        });
        if (toggle_input_standard_btn) {
            toggle_input_standard_btn.addEventListener('click', function() {
                let showStandard = Alpine.store('scheduleinput').toggleStandardVisibility();
                let showSetup = Alpine.store('scheduleinput').showSetup

                standard_table.style.display = showStandard ? "grid" : "none";
                const label = toggle_input_standard_btn.querySelector('.label')
                if (label) {
                    label.textContent = showStandard ? "Hide Standard" : "Show Standard";
                }
                combined_table_container.style.gridTemplateColumns = showSetup && showStandard ? "1fr 1fr" : "1fr";
            });
        }
    }
    document.querySelectorAll('.setup-table-row').forEach(row => {
        row.addEventListener('mouseover', () => Alpine.store('scheduleinput').onSetupRowHover(row));
        row.addEventListener('mouseout', () => Alpine.store('scheduleinput').onSetupRowMouseLeave(row));
    });


}

const initializeScheduleInput = (Alpine: CustomAlpine) => {

    return Alpine.directive('scheduleinput', (e) => {
        initializeState(Alpine);
    });
}


export {
    initializeScheduleInput,
};
